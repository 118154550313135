<template>
  <b-tabs>
    <b-tab title="Договір" active>
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidation">
        <b-form @submit.prevent="handleSubmit(onSubmit)">
          <number-field v-model="form.number" />
          <issued-date-field v-model="form.issued_date" />
          <ended-at-field v-model="form.ended_at" />
          <counterpart-field v-model="form.counterpart_id" />
          <contract-type-field v-model="form.type_id" />
          <state-field v-model="form.state_id" />
          <b-form-group
            label="Директор"
            label-size="lg"
            label-class="font-weight-bold"
          >
            <last-name-field v-model="form.last_name" />
            <first-name-field v-model="form.first_name" />
            <middle-name-field v-model="form.middle_name" />
          </b-form-group>
          <payment-methods-field v-model="form.payment_methods" />
          <financial-model-field v-model="form.financial_model_id" />
          <payment-destination-code-field
            v-if="!isOutcomingContract(contract.type)"
            v-model="form.payment_destination_code_id"
          />
          <payment-destination-note-field
            v-if="
              !isOutcomingContract(contract.type) &&
                isSpecialCode(form.payment_destination_code_id)
            "
            v-model="form.payment_destination_note"
            :payment-destination-code-id="form.payment_destination_code_id"
          />
          <payout-destination-code-field
            v-if="
              isOutcomingContract(contract.type) ||
                isMixedContract(contract.type)
            "
            v-model="form.payout_destination_code_id"
          />
          <payout-destination-note-field
            v-if="
              (isOutcomingContract(contract.type) ||
                isMixedContract(contract.type)) &&
                isSpecialCode(form.payout_destination_code_id)
            "
            v-model="form.payout_destination_note"
            :payout-destination-code-id="form.payout_destination_code_id"
          />
          <upload-documents-field
            :name="'contracttsDos'"
            :id="$route.params.id"
          />

          <b-button
            v-if="$auth.can('create contract')"
            type="submit"
            :disabled="submitting"
            size="sm"
            variant="primary"
          >
            <i class="fa fa-dot-circle-o"></i> Зберегти
          </b-button>
        </b-form>
      </ValidationObserver>
    </b-tab>
  </b-tabs>
</template>
<script>
import typeMixin from "@/entity/Contracts/mixins/type";
import NumberField from "@/entity/Contracts/components/NumberField.vue";
import IssuedDateField from "@/entity/Contracts/components/IssuedDateField.vue";
import EndedAtField from "@/entity/Contracts/components/EndedAtField.vue";
import CounterpartField from "@/entity/Contracts/components/CounterpartField.vue";
import ContractTypeField from "@/entity/Contracts/components/ContractTypeField.vue";
import StateField from "@/entity/Contracts/components/StateField.vue";
import LastNameField from "@/entity/Contracts/components/LastNameField.vue";
import FirstNameField from "@/entity/Contracts/components/FirstNameField.vue";
import MiddleNameField from "@/entity/Contracts/components/MiddleNameField.vue";
import PaymentMethodsField from "@/entity/Contracts/components/PaymentMethodsField.vue";
import FinancialModelField from "@/entity/Contracts/components/FinancialModelField.vue";
import PaymentDestinationCodeField from "@/entity/Contracts/components/PaymentDestinationCodeField.vue";
import PaymentDestinationNoteField from "@/entity/Contracts/components/PaymentDestinationNoteField.vue";
import PayoutDestinationCodeField from "@/entity/Contracts/components/PayoutDestinationCodeField.vue";
import PayoutDestinationNoteField from "@/entity/Contracts/components/PayoutDestinationNoteField.vue";
import UploadDocumentsField from "@/entity/Contracts/components/UploadDocumentsField.vue";

export default {
  props: ["contract", "submitting"],
  name: "Code3810ContractFrom",
  mixins: [typeMixin],
  components: {
    NumberField,
    IssuedDateField,
    EndedAtField,
    CounterpartField,
    ContractTypeField,
    StateField,
    LastNameField,
    FirstNameField,
    MiddleNameField,
    PaymentMethodsField,
    FinancialModelField,
    PaymentDestinationCodeField,
    PaymentDestinationNoteField,
    PayoutDestinationCodeField,
    PayoutDestinationNoteField,
    UploadDocumentsField
  },
  data() {
    return {
      form: {
        id: null,
        number: "",
        issued_date: "",
        ended_at: "",
        counterpart_id: null,
        type_id: null,
        state_id: null,
        last_name: "",
        first_name: "",
        middle_name: "",
        payment_methods: [],
        financial_model_id: null,
        payment_destination_code_id: null,
        payment_destination_note: "",
        payout_destination_code_id: null,
        payout_destination_note: ""
      }
    };
  },
  watch: {
    contract: {
      deep: true,
      immediate: true,
      handler(value) {
        this.form.id = value.id;
        this.form.number = value.number || "";
        this.form.issued_date = value.issued_date || "";
        this.form.ended_at = value.ended_at || "";
        this.form.counterpart_id = value.counterpart?.id || null;
        this.form.type_id = value.type?.id || null;
        this.form.state_id = value.state?.id || null;
        this.form.last_name = value.last_name || "";
        this.form.first_name = value.first_name || "";
        this.form.middle_name = value.middle_name || "";
        this.form.payment_methods = value.payment_methods.length
          ? value.payment_methods.map(item => item.id)
          : [];
        this.form.financial_model_id = value.financial_model?.id || null;
        this.form.payment_destination_code_id =
          value.payment_destination_code?.id || null;
        this.form.payment_destination_note = value.payment_destination_note;
        this.form.payout_destination_code_id =
          value.payout_destination_code?.id || null;
        this.form.payout_destination_note = value.payout_destination_note || "";

        requestAnimationFrame(() => {
          this.$refs.formValidation.reset();
        });
      }
    }
  },
  computed: {
    getChangedFields() {
      const keys = Object.keys(this.$refs.formValidation.fields).reduce(
        (accumulator, key) => {
          if (
            this.$refs.formValidation.fields[key].changed === true &&
            key in this.form
          ) {
            accumulator.push(key);
          }

          return accumulator;
        },
        []
      );

      return Object.keys(this.form).reduce(
        (accumulator, key) => {
          if (keys.includes(key)) {
            accumulator[key] = this.form[key];
          }

          return accumulator;
        },
        { id: this.form.id }
      );
    }
  },
  methods: {
    onSubmit() {
      this.$emit("send-form", this.getChangedFields);
    }
  }
};
</script>
